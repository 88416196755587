import { Box, DialogActions, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFTNumericTextField } from '../hook-form';
import { PrimaryDarkButton } from '../ui';
import { LoadingModal } from '../LoadingModal';
import { SuccessModal } from '../SuccessModal';
import { CustomButton } from '../games/modals/styles';
import { useGetWusdBalance, useTransferWusd } from '../../api';
import { useBreakpointDown } from '../../hooks';
import { formatBigNumber } from '../../utils';
import { SET_BALANCE_BUTTONS } from './constants';
import { schema } from './schema';

const defaultValues = {
  walletAddress: '',
  wusdAmount: '',
};
export function TransferWPoint() {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const { data } = useGetWusdBalance();
  const isDownSm = useBreakpointDown('sm');

  const wusdBalance = data ? formatBigNumber(`${data}`, 9) : 0;

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit, getValues, setValue } = methods;
  const { walletAddress, wusdAmount } = getValues();

  const { prepareContractError, isLoadingAnyState, hash, write } =
    useTransferWusd({
      onSettledTransaction() {
        setLoadingModalOpen(false);
        // setValue('walletAddress', '');
        // setValue('wusdAmount', '');
      },
      onSuccessTransaction() {
        setSuccessModalOpen(true);
      },
      onSubmittedTransaction() {
        setLoadingModalOpen(true);
      },
      transferAmount: wusdAmount,
      receiver: walletAddress,
    });

  const submitHandler = () => {
    if (prepareContractError) {
      enqueueSnackbar(prepareContractError.reason, { variant: 'error' });
      return;
    }
    write?.();
  };

  const handleBalance = (index) => {
    if (index === 0) {
      setValue('wusdAmount', ((+wusdBalance * 25) / 100).toString());
    } else if (index === 1) {
      setValue('wusdAmount', ((+wusdBalance * 50) / 100).toString());
    } else if (index === 2) {
      setValue('wusdAmount', ((+wusdBalance * 75) / 100).toString());
    } else setValue('wusdAmount', (+wusdBalance).toString());
  };

  return (
    <>
      <div className="flex flex-col gap-y-1.5">
        <FormProvider onSubmit={handleSubmit(submitHandler)} methods={methods}>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={2}
            maxWidth={780}
            mx="auto"
          >
            <RHFTextField
              name="walletAddress"
              InputLabelProps={{ shrink: true }}
              label="Wallet Address"
              color="secondary"
              autoComplete="off"
            />
            <Box display="flex" flexDirection="column" rowGap={1}>
              <RHFTNumericTextField
                name="wusdAmount"
                InputLabelProps={{ shrink: true }}
                label="WPoint Amount"
                color="secondary"
                allowNegative={false}
              />{' '}
              <div className="flex items-center justify-between">
                {' '}
                <Typography
                  variant={isDownSm ? 'caption' : 'body2'}
                  className="flex items-center gap-x-1"
                >
                  <span className="font-bold">WPoint</span> balance :{' '}
                  {wusdBalance}
                </Typography>
                <Box
                  display="flex"
                  columnGap={1}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {SET_BALANCE_BUTTONS.map(({ styles, text }, index) => (
                    <PrimaryDarkButton
                      key={text}
                      sx={styles}
                      size={isDownSm ? 'small' : 'medium'}
                      variant="contained"
                      onClick={() => handleBalance(index)}
                    >
                      {text}
                    </PrimaryDarkButton>
                  ))}
                </Box>
              </div>
            </Box>
          </Box>

          <DialogActions
            sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
          >
            <CustomButton
              className="bg-primary-dark"
              type="submit"
              disabled={isLoadingAnyState || !wusdAmount || !walletAddress}
              sx={{ typography: 'subtitle2' }}
              variant="contained"
            >
              Transfer
            </CustomButton>
          </DialogActions>
        </FormProvider>
      </div>
      <LoadingModal open={loadingModalOpen} />
      <SuccessModal
        title="Transfer WPoint"
        content="Transfer was Successful"
        open={successModalOpen}
        handleClose={() => {
          setSuccessModalOpen(false);
        }}
        hash={hash}
      />
    </>
  );
}
