import { bsc } from 'wagmi/chains';
import { MINT_GENX_ABI, USDT_ABI, GENX_ABI } from '../../ABI';
import { WUSD_CONTRACT_ABI } from '../../api';

export const MINT_GENX_CONTRACT_ADDRESS =
  '0x7620ac7b57685b1a0d1cd6d0eaea2f705419f30d';

export const USDT_CONTRACT_ADDRESS =
  '0x55d398326f99059fF775485246999027B3197955';

export const WUSD_CONTRACT_ADDRESS =
  '0xD2eD8f606C10C497cAf693D558f49B7218ce9310';

export const GENX_CONTRACT_ADDRESS =
  '0x7620ac7b57685b1a0d1cd6d0eaea2f705419f30d';

export const genxMintConfig = {
  address: MINT_GENX_CONTRACT_ADDRESS,
  abi: MINT_GENX_ABI,
  chainId: bsc.id,
};

export const usdtConfig = {
  address: USDT_CONTRACT_ADDRESS,
  abi: USDT_ABI,
  chainId: bsc.id,
};

export const wusdConfig = {
  address: WUSD_CONTRACT_ADDRESS,
  abi: WUSD_CONTRACT_ABI,
  chainId: bsc.id,
};

export const genxConfig = {
  address: GENX_CONTRACT_ADDRESS,
  abi: GENX_ABI,
  chainId: bsc.id,
};
