import { createSlice } from '@reduxjs/toolkit';
import { parsedCookies, setCookie } from '../../utils/cookies';
import {
  authThunk,
  getUsersThunk,
  notificationThunk,
  verifyAuthThunk,
} from '../thunks/auth-thunk';

const initialState = {
  clientAddress: null,
  id: null,
  wallet: null,
  balance: 0,
  activeBabyWoj: false,
  babyWojExpireAt: null,
  nons: '',
  token: null,
  verifyToken: null,
  isActive: false,
  incomeFromSubsets: null,
  notifications: [],
  activationDialogOpen: false,
  isLoading: false,
  error: null,
  notificationsLoading: false,
  notificationsError: false,
  activeSpecialReward: false,
  getUsersLoading: false,
  getUsersError: false,
  level: null,
  canChangeSponsorsPlan: false,
  sponsorsPlan: 'PLAN_1',
  avatar: null,
  gem: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setClientAddress(stata, action) {
      stata.clientAddress = action.payload;
    },
    activateSpecialReward(state) {
      state.activeSpecialReward = true;
    },
    clearClientAddress(stata) {
      stata.clientAddress = null;
    },
    handleVerificationToken(state, action) {
      state.verifyToken = action.payload;
    },
    logout(state) {
      state.id = null;
      state.wallet = null;
      state.balance = 0;
      state.isActive = false;
      state.incomeFromSubsets = null;
      state.isActive = null;
      state.token = null;
      state.nons = null;
      state.verifyToken = null;
      state.clientAddress = null;
      state.avatar = null;
      state.gem = 0;
    },
    handleActive(state) {
      state.isActive = true;
      setCookie(
        'verify',
        JSON.stringify({
          token: parsedCookies?.token,
          active: true,
          address: parsedCookies?.address,
        }),
        6
      );
    },
    handleDeactive(state) {
      state.isActive = false;
    },
    openActivationDialog(state) {
      state.activationDialogOpen = true;
    },
    closeActivationDialog(state) {
      state.activationDialogOpen = false;
    },

    clearUsersError(state) {
      state.getUsersError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(authThunk.fulfilled, (state, action) => {
      const { token, nons } = action.payload;
      state.isLoading = false;
      state.error = null;
      state.token = token;
      state.nons = nons;
    });
    builder.addCase(authThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || action.payload;
    });

    // * Verify Auth thunk

    builder.addCase(verifyAuthThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyAuthThunk.fulfilled, (state, action) => {
      const { token, id, wallet, balance, active, incomeFromSubsets } =
        action.payload;
      state.isLoading = false;
      state.error = null;
      state.id = id;
      state.wallet = wallet;
      state.balance = balance;
      state.verifyToken = token;
      state.isActive = active;
      state.incomeFromSubsets = incomeFromSubsets;
    });
    builder.addCase(verifyAuthThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || action.payload;
    });

    // * Notifications

    builder.addCase(notificationThunk.pending, (state) => {
      state.notificationsLoading = true;
    });
    builder.addCase(notificationThunk.fulfilled, (state, action) => {
      state.notificationsLoading = false;
      state.notificationsError = null;
      state.notifications = action.payload;
    });
    builder.addCase(notificationThunk.rejected, (state, action) => {
      state.notificationsLoading = false;
      state.notificationsError = action.error.message || action.payload;
    });

    // * Get Users

    builder.addCase(getUsersThunk.pending, (state) => {
      state.getUsersLoading = true;
      state.getUsersError = null;
    });
    builder.addCase(getUsersThunk.fulfilled, (state, action) => {
      const {
        id,
        wallet,
        balance,
        active,
        incomeFromSubsets,
        activeSpecialReward,
        babyWojExpireAt,
        activeBabyWoj,
        level,
        canChangeSponsorsPlan,
        sponsorsPlan,
        avatar,
        gem,
      } = action.payload;
      state.getUsersLoading = false;
      state.getUsersError = null;
      state.id = id;
      state.wallet = wallet;
      state.balance = balance;
      state.isActive = active;
      state.incomeFromSubsets = incomeFromSubsets;
      state.activeSpecialReward = activeSpecialReward;
      state.babyWojExpireAt = babyWojExpireAt;
      state.activeBabyWoj = activeBabyWoj;
      state.level = level;
      state.canChangeSponsorsPlan = !canChangeSponsorsPlan
        ? false
        : canChangeSponsorsPlan;

      state.sponsorsPlan = !sponsorsPlan ? 'PLAN_1' : sponsorsPlan;
      state.avatar = avatar;
      state.gem = gem;
    });
    builder.addCase(getUsersThunk.rejected, (state, action) => {
      state.getUsersLoading = false;
      state.getUsersError = action.payload || action.error;
    });
  },
});

export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentAvatar = (state) => state.auth.avatar;
export const selectCurrentId = (state) => state.auth.id;
export const selectCurrentLevel = (state) => state.auth.level;
export const selectCurrentVerifyToken = (state) => state.auth.verifyToken;
export const selectCurrentNonce = (state) => state.auth.nons;
export const selectCurrentBalance = (state) => state.auth.balance;
export const selectIsActive = (state) => state.auth.isActive;
export const selectActiveBabyWoj = (state) => state.auth.activeBabyWoj;
export const selectBabyWojExpireAt = (state) => state.auth.babyWojExpireAt;
export const activeDialogOpen = (state) => state.auth.activationDialogOpen;
export const authLoading = (state) => state.auth.isLoading;
export const authError = (state) => state.auth.error;
export const SelectActiveSpecialReward = (state) =>
  state.auth.activeSpecialReward;
export const selectGetUsersError = (state) => state.auth.getUsersError;
export const selectGetUsersLoading = (state) => state.auth.getUsersLoading;
export const selectIncomeFromSubset = (state) => state.auth.incomeFromSubsets;
export const selectNotifications = (state) => state.auth.notifications;
export const selectCanChangeSponsorsPlan = (state) =>
  state.auth.canChangeSponsorsPlan;

export const selectSponsorsPlan = (state) => state.auth.sponsorsPlan;

export const selectGem = (state) => state.auth.gem;

export const {
  logout,
  setCredentials,
  handleActive,
  handleDeactive,
  closeActivationDialog,
  openActivationDialog,
  clearClientAddress,
  setClientAddress,
  handleVerificationToken,
  activateSpecialReward,
  clearUsersError,
} = authSlice.actions;
export default authSlice.reducer;
