import { Card, CircularProgress, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormattedNumber } from 'react-intl';
import { isUndefined } from 'lodash';
import { AuthGuard } from '../auth-guard';
import { StyledBadge } from '../ui';
import { MovingGradient } from '../MovingGradient';

import { useCopyToClipboard } from '../../hooks';
import { Ico } from './contracts';
import { LogoAndSocials } from './components';

const FUCOIN_ADDRESS = '0xed6af21df463c7f116a6d7d687a4c190c4cf7586';

export function LaunchPad() {
  const { scores } = Ico.useScores();

  const { price } = Ico.useFuCoinPrice();

  const { copy, isCopied } = useCopyToClipboard(FUCOIN_ADDRESS);

  const arePriceAndScoresLoaded = !isUndefined(scores) && !isUndefined(price);

  return (
    <AuthGuard>
      <Card
        className="relative flex items-center justify-center min-h-[300px] p-6"
        variant="outlined"
      >
        <Card className="p-4 bg-black lg:w-[40%] xl:w-1/3">
          <div className="flex flex-col items-start">
            <StyledBadge
              className="flex text-xs items-center gap-x-1 p-1.5 uppercase"
              isSuccess
            >
              <VerifiedIcon className="!w-4 !h-4 fill-success-dark" />
              <span>Launched</span>
            </StyledBadge>
            <LogoAndSocials tokenPrice={<TokenPrice price={price} />} />
            <div>
              <div className="mt-8">
                <Typography variant="body">
                  What is Fu? Fu is luck, a wild, wild ride
                </Typography>
                <Typography variant="body">
                  In your wallet, it’s all on your side!
                </Typography>
              </div>
            </div>
            <div className="flex flex-col gap-y-6 mt-6">
              <div className="flex items-center text-sm">
                <Typography variant="body2" color="text.seconday">
                  Contract:
                  <span className="text-white font-bold ml-1">
                    {formatWalletAddress(FUCOIN_ADDRESS)}
                  </span>
                </Typography>
                <button onClick={copy} type="button">
                  {isCopied ? (
                    <CheckCircleIcon color="success" fontSize="small" />
                  ) : (
                    <ContentCopyIcon fontSize="small" />
                  )}
                </button>
              </div>
              <div className="flex items-center text-sm">
                <Typography
                  variant="body2"
                  component="div"
                  color="text.seconday"
                >
                  Your Score:
                  {arePriceAndScoresLoaded ? (
                    <span className="text-white font-bold ml-1">
                      {scores ? (
                        <FormattedNumber value={scores} />
                      ) : (
                        "You don't have a score in this ICO"
                      )}
                    </span>
                  ) : (
                    <CircularProgress size={15} className="ml-1.5" />
                  )}
                </Typography>
              </div>
            </div>
          </div>
        </Card>

        <MovingGradient
          sx={{
            width: '60%',
            height: '100%',
            top: 0,
            right: 0,
            color: 'secondary.main',
            opacity: '40%',
          }}
          locked
        />
      </Card>
    </AuthGuard>
  );
}

export const formatWalletAddress = (address) =>
  `${address.substring(0, 6)}••••${address.substring(
    address.length - 5,
    address.length
  )}`;

function formatSmallNumber(price) {
  const numberOfZeros = price.toExponential().split('e')[1];

  const fixedString = price.toFixed(Math.abs(+numberOfZeros - 2));

  // Find the index of the first non-zero digit after the decimal point
  const match = fixedString.match(/0\.0*(\d+)/);

  if (match && price) {
    const leadingZeros = Math.abs(+numberOfZeros + 1); // Calculate number of leading zeros
    const significantDigits = match[1]; // The digits after the leading zeros
    return (
      <span>
        0.0<span className="relative top-1 text-sm">{leadingZeros}</span>
        <span>{significantDigits}</span>
      </span>
    );
  }

  // If somehow there's no match (very unlikely), return the number as is
  return (
    <span className="text-lg font-bold">
      {price.toFixed(Math.abs(+numberOfZeros - 2))}
    </span>
  );
}

function TokenPrice({ price }) {
  const formattedPrice = formatSmallNumber(price);

  return <div className="text-xs font-bold">{formattedPrice}</div>;
}
