import { useSnackbar } from 'notistack';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { bsc } from '@wagmi/chains';
import { getBigNumber } from '../../../../utils';
import { WUSD_CONTRACT_ABI } from './wusd-abi';
import { WUSD_CONTRACT_ADDRESS } from './address';

export function useTransferWusd({
  transferAmount,
  onSettledTransaction,
  onSubmittedTransaction,
  onSuccessTransaction,
  receiver,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    config,
    isLoading: isLoadingPrepareContract,
    isSuccess: isSuccessPrepareContract,
    isError: isErrorPrepareContract,
    error: prepareContractError,
  } = usePrepareContractWrite({
    abi: WUSD_CONTRACT_ABI,
    functionName: 'transfer',
    args:
      receiver && transferAmount
        ? [receiver, getBigNumber(String(transferAmount) || '0', 9)]
        : undefined,
    enabled: receiver && transferAmount,
    address: WUSD_CONTRACT_ADDRESS,
    chainId: bsc.id,
  });

  const {
    write,
    isLoading: isLoadingWriteContract,
    data,
    isSuccess: isSuccessWrite,
    error: contractError,
    isError: isErrorContractError,
    reset,
  } = useContractWrite({
    ...config,
    onSuccess() {
      onSubmittedTransaction?.();
    },
  });

  const {
    isLoading: isLoadingWaitForTransaction,
    data: transactionReceipt,
    isSuccess: isSuccessTransaction,
    error: waitForTransactionError,
  } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess(data) {
      onSuccessTransaction?.(data);
    },
    onSettled() {
      onSettledTransaction?.();
    },
    onError(error) {
      enqueueSnackbar(error.reason, { variant: 'error' });
    },
  });

  const isLoadingAnyState =
    isLoadingPrepareContract ||
    isLoadingWriteContract ||
    isLoadingWaitForTransaction;

  const isErrorAnyState = isErrorPrepareContract || isErrorContractError;

  return {
    hash: data?.hash,
    isLoadingAnyState,
    prepareContractError,
    isLoadingWaitForTransaction,
    isLoadingWriteContract,
    isSuccessPrepareContract,
    isErrorAnyState,
    isSuccessTransaction,
    isSuccessWrite,
    errors: [prepareContractError, contractError, waitForTransactionError],
    reset,
    transactionReceipt,
    write,
  };
}
